import { useDispatch, useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// actions
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';
import ModalActions from '@/Actions/Modal';

// slices
import { toggleExportMenu } from '@/Redux/Slices/UISlice';
import { selectExportAccess } from '@/Redux/Slices/ShaperHubSlice';

// components
import ActionMenuList from '@/Components/ActionMenuList/ActionMenuList';
import ExportCount from '@/Components/Export/ExportCount';
import TranslationText from '@/Components/TranslationText/TranslationText';
import Icon from '@/Styles/Icons/Icon';

// types
import { ExportTargets } from '@/Components/Export/ExportModal';

export function ExportMenu() {
  const dispatch = useDispatch();
  const { unlimited, remaining, limit } = useSelector(selectExportAccess);
  const updateWorkspaceOptions = useAction(UpdateWorkspaceOptionsAction);
  const modalActions = useAction(ModalActions);

  function showExportMenu(target: ExportTargets) {
    // hide the menus
    updateWorkspaceOptions.update({ showApplicationMenu: false });
    dispatch(toggleExportMenu(false));

    // show the modal
    modalActions.openExportModal(target);
  }

  function onBack() {
    dispatch(toggleExportMenu(false));
  }

  function onExportToOrigin() {
    showExportMenu('origin');
  }

  function onDownloadSVG() {
    showExportMenu('svg');
  }

  // DXF not supported yet
  // function onDownloadDXF() {
  //   showExportMenu('dxf');
  // }

  return (
    <ActionMenuList className='export-action-menu'>
      <div className='export-action-menu--header'>
        <Icon icon='arrow-left' onClick={onBack} />

        <div className='export-action-menu--header--text'>
          <TranslationText i18nKey='export'>Export</TranslationText>
        </div>

        <div className='export-action-menu--header--count'>
          {!unlimited && <ExportCount remaining={remaining!} limit={limit!} />}
        </div>
      </div>

      <ActionMenuList.SectionOption
        icon='shaper-origin'
        onClick={onExportToOrigin}
      >
        <TranslationText i18nKey='export-to-origin'>
          Export to Origin
        </TranslationText>
      </ActionMenuList.SectionOption>

      <ActionMenuList.SectionOption icon='download' onClick={onDownloadSVG}>
        <TranslationText i18nKey='download-as-svg'>
          Download as SVG
        </TranslationText>
      </ActionMenuList.SectionOption>

      {/* DXF not supported yet */}
      {/* <ActionMenuList.SectionOption icon='download' onClick={onDownloadDXF} >
        <TranslationText i18nKey='download-as-dxf' >
          Download as DXF
        </TranslationText>
      </ActionMenuList.SectionOption> */}
    </ActionMenuList>
  );
}
