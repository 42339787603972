import React, { useEffect, useMemo, useRef, useState } from 'react';

// actions
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';
import { useAction } from '@/Actions/useAction';
import RenameWorkspaceAction from '@/Actions/RenameWorkspace';

//selectors
import { useSelector } from 'react-redux';
import {
  selectWorkspaceInformation,
  selectLoggedIn,
  selectProject,
  selectCreator,
  selectStudioShare,
} from '@/Redux/Slices/ShaperHubSlice';

//components
import classNames from 'classnames';
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '../TranslationText/TranslationText';
import { Tooltip } from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField/TextField';
import AlertAction from '@/Actions/Alert';
import { ALERT_TYPES, MODAL_TYPES } from '@/defaults';
import { CustomErrors } from '@/Constants/Errors';
import { selectIsMobile, selectIsPreviewView } from '@/Redux/Slices/UISlice';
import ShareBadge from '../ShareBadge/ShareBadge';

export default function ApplicationMenuLauncher() {
  const fileNameRef = useRef();

  const workspace = useSelector(selectWorkspaceInformation);
  const project = useSelector(selectProject);
  const studioShare = useSelector(selectStudioShare);
  const creator = useSelector(selectCreator);
  const isLoggedIn = useSelector(selectLoggedIn);
  const isPreviewView = useSelector(selectIsPreviewView);
  const isMobile = useSelector(selectIsMobile);
  const { t, i18n } = useTranslation();

  const name = isPreviewView ? project?.title : workspace.name;
  const [workspaceName, setWorkspaceName] = useState(name);
  const [newWorkspaceName, setNewWorkspaceName] = useState(name);
  const [editWorkspaceName, setEditWorkspaceName] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const updateWorkspaceOptions = useAction(UpdateWorkspaceOptionsAction);
  const renameWorkspaceAction = useAction(RenameWorkspaceAction);
  const alertAction = useAction(AlertAction);

  const menuTranslation = i18n.exists('main-menu')
    ? t('main-menu')
    : 'main menu';
  const renameTranslation = i18n.exists('rename') ? t('rename') : 'rename';
  const byTranslation = i18n.exists('by') ? t('by') : 'by';

  function onToggleApplicationMenu() {
    updateWorkspaceOptions.update({ showApplicationMenu: true });
  }

  function showRename() {
    if (!isPreviewView) {
      setIsSuccess(false);
      setEditWorkspaceName(!editWorkspaceName);
      setTimeout(() => {
        fileNameRef.current?.select();
      }, 10);
    }
  }

  async function rename() {
    if (newWorkspaceName !== name) {
      const renameResult = await renameWorkspaceAction.renameWorkspace(
        newWorkspaceName
      );
      if (renameResult) {
        setHasError(true);
        setIsSuccess(false);
        if (renameResult === CustomErrors.ALREADY_USED_RENAME) {
          alertAction.set({
            msg: 'Name already in use',
            i18nKey: 'name-in-use',
            type: ALERT_TYPES.ERROR_DISMISSIBLE,
            icon: 'alert-warning',
            modalIcon: 'circle-warning',
            duration: 4000,
          });
        } else if (renameResult === CustomErrors.BAD_CHARACTER_RENAME) {
          alertAction.set({
            msg: 'Invalid character',
            i18nKey: 'invalid-character',
            type: ALERT_TYPES.ERROR_DISMISSIBLE,
            modal: MODAL_TYPES.INVALID_CHARACTERS_MODAL,
            icon: 'alert-warning',
            duration: 4000,
          });
        }
      } else {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2500);
      }
    }
  }

  function onChangeWorkspaceName(e) {
    setNewWorkspaceName(e);
    setHasError(false);
  }

  function close() {
    setEditWorkspaceName(false);
    setHasError(false);
    setWorkspaceName(name);
    setNewWorkspaceName(name);
  }

  useEffect(() => {
    setWorkspaceName(name);
    setHasError(false);
    setEditWorkspaceName(false);
  }, [name]);

  const fileNameCx = classNames('application-menu--launcher--file-name', {
    'rename-file': editWorkspaceName,
    error: hasError,
    'error-shake-text': hasError,
    editing: workspaceName !== newWorkspaceName,
    success: isSuccess,
    editable: !isPreviewView,
  });

  // TODO: need to switch from using the logged in user to using the user on the workspace
  const fileAuthor = useMemo(() => {
    if (isPreviewView && creator) {
      return creator.familyName
        ? `${byTranslation} ${creator.givenName} ${creator.familyName}`
        : `${byTranslation} ${creator.username}`;
    }
    return '';
  }, [isPreviewView, creator]);

  const getFileName = () => {
    const innerComponent = (
      <div
        data-cy='launcher-filename'
        className={fileNameCx}
        onClick={showRename}
      >
        {name} {isSuccess && <Icon icon='check' />}
      </div>
    );
    if (!isPreviewView) {
      return (
        <Tooltip tip={renameTranslation.toLowerCase()} side='under'>
          {innerComponent}
        </Tooltip>
      );
    }

    return (
      <div>
        {innerComponent}
        {isPreviewView && (
          <div
            className='application-menu--launcher--file-author'
            data-cy='launcher-file-author'
          >
            {fileAuthor}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='application-menu--launcher' role='menu'>
      <Tooltip tip={menuTranslation} side='right' className='tooltip-icon'>
        <Icon
          icon='shaper-icon'
          className='application-menu--launcher--icon'
          onClick={onToggleApplicationMenu}
          data-cy='application-menu-launcher'
        />
      </Tooltip>

      {isLoggedIn || isPreviewView ? (
        <>
          {editWorkspaceName ? (
            <>
              <TextField
                className={fileNameCx}
                inputRef={fileNameRef}
                onChange={onChangeWorkspaceName}
                onSubmit={rename}
                onBlur={close}
                submitOnEnter
                value={workspaceName}
              />
            </>
          ) : (
            getFileName()
          )}
        </>
      ) : (
        <div className={fileNameCx}>
          <TranslationText i18nKey='demo'>Demo</TranslationText>
        </div>
      )}

      {!isPreviewView && !isMobile && studioShare && <ShareBadge />}
    </div>
  );
}
