import { FeatureFlagsMap } from '@/Helpers/FeatureFlagClient';
import { enableDebugging } from '@/Sync/SyncLog';
import {
  createSlice,
  isAction,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import { LocaleResponse } from '@/@types/shaper-types';
import { getDeveloperSettings } from '@/Utility/developer-settings';
import { setI18nLanguage } from '@/i18n';
import { RootState, AppThunkDispatch } from '../store';
import { SvgOps } from '@/Geometry/SvgParser';
import svgCache from '@/Geometry/SvgCache/SvgCache';
import { getShaperSubscriptions } from './ShaperHubSlice';

export const DEFAULT_FEATURE_FLAGS: FeatureFlagsMap = {
  'is-shaper-employee': false,
  'studio-debug-logs': false,
  'studio-developer-settings': false,
  'studio-fonts': false,
  'release-studio-free': false,
  'studio-polyline-feature': false,
  'studio-tessellation-feature': false,
  'release-project-sharing': false,
  'release-no-new-studio-trials': false,
};

export const updateFeatureFlags = (
  featureFlags: FeatureFlagsMap,
  locale: LocaleResponse
) => ({
  type: 'featureFlags/featureFlagsUpdated',
  payload: {
    featureFlags,
    locale,
  },
});

// Initial state for feature flags
export const initialFeatureFlagState = {
  featureFlags: DEFAULT_FEATURE_FLAGS,
};

// Create a feature flags slice
const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: initialFeatureFlagState,
  reducers: {
    featureFlagsUpdated(
      state,
      action: PayloadAction<{
        featureFlags: FeatureFlagsMap;
        locale: LocaleResponse;
      }>
    ) {
      state.featureFlags = action.payload.featureFlags;

      if (state.featureFlags['studio-debug-logs']) {
        enableDebugging();
      }

      if (state.featureFlags['studio-developer-settings']) {
        const devLocale = getDeveloperSettings('locale');
        setI18nLanguage(devLocale || action.payload.locale.language);
      }

      if (state.featureFlags['studio-tessellation-feature']) {
        SvgOps.setFeatureFlag(true);
        svgCache.setFeatureFlag(true);
      }
    },
  },
});

export const featureFlagsMiddleware: Middleware<
  {},
  RootState,
  AppThunkDispatch
> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      isAction(action) &&
      action.type === 'featureFlags/featureFlagsUpdated'
    ) {
      dispatch(getShaperSubscriptions());
    }

    return next(action);
  };

export const selectFeatureFlags = (state: RootState) =>
  state.featureFlags.featureFlags;
export const selectTessellationFeatureFlag = (state: RootState) =>
  !!state.featureFlags.featureFlags['studio-tessellation-feature'];

export const selectFeatureFlag = (
  state: RootState,
  flagName: keyof FeatureFlagsMap
) =>
  state.featureFlags.featureFlags
    ? state.featureFlags.featureFlags[flagName]
    : false;
export default featureFlagsSlice.reducer;
