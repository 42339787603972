import {
  selectCreator,
  selectLoggedIn,
  selectProject,
} from '@/Redux/Slices/ShaperHubSlice';
import { syncToMyFiles } from '@/ShaperHub/ShaperHubThunks';
import { Avatar } from '@mui/material';
import { ShaperButton, SyncedBadge } from '@shapertools/web-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export default function PreviewFileName() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const byTranslation = i18n.exists('by') ? t('by') : 'by';
  const syncButtonTranslation = i18n.exists('sync-button')
    ? t('sync-button')
    : 'Sync to my files';
  const previewingTranslation = i18n.exists('previewing-design')
    ? t('previewing-design')
    : 'Previewing Shared Design';
  const syncedTranslation = i18n.exists('sync-badge')
    ? t('sync-badge')
    : 'Synced';

  const project = useSelector(selectProject);
  const creator = useSelector(selectCreator);
  const loggedIn = useSelector(selectLoggedIn);
  const { fileName, creatorName, avatarInitial, imgUrl } = useMemo(() => {
    if (creator) {
      return {
        fileName: project?.title || '',
        creatorName: creator.familyName
          ? `${byTranslation} ${creator.givenName} ${creator.familyName}`
          : `${byTranslation} ${creator.username}`,
        avatarInitial: creator.username.substring(0, 1).toUpperCase(),
        imgUrl: creator.profileImage
          ? `https://${creator.profileImage.bucket}/${creator.profileImage.key}`
          : null,
      };
    }
    return {
      fileName: '',
      creatorName: '',
      avatarInitial: '',
      imgUrl: null,
    };
  }, [project]);
  const hasSyncedProject = project?.viewingUserHasSynced || false;

  const sync = () => {
    if (project) {
      dispatch(syncToMyFiles(project._id));
    }
  };

  return (
    <div className='preview--file-name'>
      <div className='preview--file-name--container'>
        <div className='preview--file-name--title'>{previewingTranslation}</div>
        <div className='preview--file-name--file'>{fileName}</div>
        <div className='preview--file-name--author'>
          <div className='preview--file-name--avatar' data-cy='preview-avatar'>
            {imgUrl ? (
              <Avatar sx={{ width: 32, height: 32 }} src={imgUrl} />
            ) : (
              <Avatar sx={{ width: 32, height: 32 }}>{avatarInitial}</Avatar>
            )}
          </div>
          <div className='preview--file-name--name' data-cy='preview-creator'>
            {creatorName}
          </div>
        </div>
      </div>
      {loggedIn && (
        <div className='preview--file-name--actions-container'>
          <div className='preview--file-name--badge'>
            {hasSyncedProject ? (
              <SyncedBadge label={syncedTranslation} onClick={() => {}} />
            ) : (
              <ShaperButton
                variant='primary'
                label={syncButtonTranslation}
                onClick={sync}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
