import { ShaperButton, SyncedBadge } from '@shapertools/web-ui';

// components
import CanvasActionMenu from '../CanvasActionMenu';
import { useAction } from '@/Actions/useAction';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLoggedIn, selectProject } from '@/Redux/Slices/ShaperHubSlice';
import { useMemo } from 'react';
import { syncToMyFiles } from '@/ShaperHub/ShaperHubThunks';
import { useAppDispatch } from '@/Redux/hooks';
import { duplicate } from '@/Sync/SyncThunks';
import UIViewAction from '@/Actions/UIView';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';
import ModalActions from '@/Actions/Modal';

type Props = {
  edge: 'left' | 'right' | 'bottom';
  disableAll?: boolean;
};

// primary canvas menu
export default function PreviewActionMenu(props: Props) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const syncButtonTranslation = i18n.exists('sync-button')
    ? t('sync-button')
    : 'Sync to my files';
  const editTranslation = i18n.exists('edit-button')
    ? t('edit-button')
    : 'Edit';
  const loginToSaveTranslation = i18n.exists('login-to-save')
    ? t('login-to-save')
    : 'Log In To Save';
  const syncedTranslation = i18n.exists('sync-badge')
    ? t('sync-badge')
    : 'Synced';

  const project = useSelector(selectProject);
  const hasSyncedProject = project?.viewingUserHasSynced || false;
  const isLoggedIn = useSelector(selectLoggedIn);

  const uiViewAction = useAction(UIViewAction);
  const modalActions = useAction(ModalActions);

  function sync() {
    if (project) {
      dispatch(syncToMyFiles(project._id));
    }
  }

  function onEditDesign() {
    if (project) {
      dispatch(setLoading(true));
      dispatch(duplicate({ name: project.title, overrideIsDuplicate: false }));
    }
    uiViewAction.activate('default');
  }

  function login() {
    modalActions.openSignInModal();
  }

  // create each action
  const actions = useMemo(
    () => [
      {
        id: 'sync-badge',
        className: 'sync-badge',
        dataCy: 'sync-badge',
        visible: hasSyncedProject,
        content: <SyncedBadge label={syncedTranslation} onClick={() => {}} />,
      },
      {
        id: 'sync-button',
        className: 'sync-button',
        i18nKey: 'sync-button',
        visible: !hasSyncedProject && isLoggedIn,
        content: (
          <ShaperButton
            variant='primary'
            label={syncButtonTranslation}
            onClick={sync}
          />
        ),
      },
      {
        id: 'edit-button',
        className: 'edit-design',
        dataCy: 'edit-design',
        visible: hasSyncedProject,
        content: (
          <ShaperButton
            variant='default'
            label={editTranslation}
            onClick={onEditDesign}
          />
        ),
      },
      {
        id: 'login-button',
        i18nKey: 'login',
        className: 'login-button',
        visible: !isLoggedIn,
        content: (
          <ShaperButton
            variant='primary'
            label={loginToSaveTranslation}
            onClick={login}
          />
        ),
      },
    ],
    [hasSyncedProject, isLoggedIn]
  );

  return (
    <CanvasActionMenu
      onSelectAction={() => {}}
      className='preview-action-menu'
      edge={props.edge}
      invert
      actions={actions}
      disableAll={props.disableAll}
      dataCy='preview-context'
    />
  );
}
