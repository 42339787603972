import React, { useState } from 'react';

//actions
import { useAction } from '@/Actions/useAction';
import ModalActions from '@/Actions/Modal';

//selectors
import { useDispatch, useSelector } from 'react-redux';

//components
import Modal from '../Modal';
import MuiModal from '@mui/material/Modal';
import Icon from '@/Styles/Icons/Icon';
import LoginForm from '../../LoginMenuContainer/LoginForm';
import { menuLinks, MODAL_TRIGGER_TYPES, signupURL } from '../../../defaults';
import TranslationText from '../../TranslationText/TranslationText';
import { createAccountAction } from '../../../Constants/Analytics';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';
import { selectIsPreviewView } from '@/Redux/Slices/UISlice';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@/Components/TextField/TextField';
import { ShaperButton } from '@shapertools/web-ui';
import { sendForgotPasswordEmail } from '@/ShaperHub/ShaperHubThunks';
import { useTranslation } from 'react-i18next';

export default function SignInModal() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [variant, setVariant] = useState<'disabled' | 'primary'>('disabled');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const featureFlags = useSelector(selectFeatureFlags);
  const isPreviewView = useSelector(selectIsPreviewView);

  const resetPasswordTranslation = i18n.exists('reset-password')
    ? t('reset-password')
    : 'Reset Password';
  const hasNewLoginModal = featureFlags['release-studio-free'] || isPreviewView;

  const modalActions = useAction(ModalActions);

  const forgotPassword = () => {
    setOpen(true);
  };

  const sendForgotPassword = async () => {
    if (variant === 'primary') {
      if (email && email.length > 0) {
        const successfulSend = await sendForgotPasswordEmail(email);
        if (successfulSend) {
          setIsSuccess(true);
          setIsError(false);
        } else {
          setIsSuccess(false);
          setIsError(true);
        }
      } else {
        setIsSuccess(false);
        setIsError(true);
      }
    }
  };

  const createAccount = () => {
    dispatch(createAccountAction);
    window.open(signupURL(), '_self');
  };

  const updateSubmitButton = (newEmail: string) => {
    setEmail(newEmail);
    if (newEmail.length > 0) {
      setVariant('primary');
    } else {
      setVariant('disabled');
    }
  };

  const closeForgotPassword = () => {
    setOpen(false);
  };

  if (hasNewLoginModal) {
    return (
      <>
        <Modal className='sign-in-modal studio-free' dataCy='sign-in-modal'>
          <Modal.Panel position='left'>
            <Modal.Content>
              <div className='sign-in-modal--left--content-icon'>
                <Icon icon='shaper' />
              </div>
              <Modal.Title>
                <TranslationText i18nKey='sign-in-text-2'>
                  Sign in to Your Shaper Account
                </TranslationText>
              </Modal.Title>
              <div className='sign-in-modal--left--content-form'>
                <LoginForm hideHelpText={true} />
              </div>
              <div className='sign-in-modal--left--content-helper-text'>
                <div className='forgot-password' onClick={forgotPassword}>
                  <span>
                    <TranslationText i18nKey='forgot-password'>
                      Forgot your password?
                    </TranslationText>
                  </span>
                </div>
              </div>
              <div className='sign-in-modal separator' />
              <div className='sign-in-modal--left--content-helper-text'>
                <div className='no-account'>
                  <TranslationText i18nKey='no-account'>
                    Don't have an account?
                  </TranslationText>
                </div>
                <div className='create-account' onClick={createAccount}>
                  <span>
                    <TranslationText i18nKey='create-account'>
                      Create Shaper Account
                    </TranslationText>
                  </span>
                </div>
              </div>
            </Modal.Content>
          </Modal.Panel>
        </Modal>
        <MuiModal open={open} onClose={closeForgotPassword}>
          <Stack className='forgot-password-modal'>
            <Box className='forgot-password-modal--close'>
              <Icon icon='close' onClick={closeForgotPassword} />
            </Box>
            <Box className='forgot-password-modal--title'>
              <TranslationText i18nKey='forgot-password-title'>
                Forgot password?
              </TranslationText>
            </Box>
            <Box className='forgot-password-modal--email'>
              <div className='component__login-form'>
                <form>
                  <div className='component__login-form__fields'>
                    <div className='component__login-form__label'>
                      <TranslationText i18nKey='email'>Email</TranslationText>
                    </div>
                    <TextField
                      placeholder='name@shapertools.com'
                      type='text'
                      onChange={updateSubmitButton}
                      submitOnEnter={true}
                      inputProps={{
                        'data-cy': 'forgot-password-email-input',
                      }}
                    />
                  </div>
                </form>
              </div>
            </Box>
            {isSuccess && (
              <Box className='forgot-password-modal--error'>
                <TranslationText
                  i18nKey='password-reset-success'
                  customKeys={{ email: email }}
                >
                  {`A password reset email has been sent to ${email}. Please check
                  your inbox for further instructions.`}
                </TranslationText>
              </Box>
            )}
            {isError && (
              <Box className='forgot-password-modal--error'>
                <TranslationText i18nKey='password-reset-error'>
                  A user account could not be found with the given email
                </TranslationText>
              </Box>
            )}
            <Box className='forgot-password-modal--submit-button'>
              <ShaperButton
                label={resetPasswordTranslation}
                variant={variant}
                onClick={sendForgotPassword}
              />
            </Box>
            <Box className='forgot-password-modal--cancel'>
              <span onClick={closeForgotPassword}>
                <TranslationText i18nKey='nevermind-go-back'>
                  Nevermind, Sign In
                </TranslationText>
              </span>
            </Box>
          </Stack>
        </MuiModal>
      </>
    );
  }

  return (
    <Modal className='sign-in-modal' dataCy='sign-in-modal'>
      <Modal.Panel position='left'>
        <Modal.Content>
          <div className='sign-in-modal--left--content-icon'>
            <Icon icon='shaper' />
          </div>
          <Modal.Title>
            <TranslationText i18nKey='sign-in-text'>
              Sign In to Shaper
            </TranslationText>
          </Modal.Title>
          <div className='sign-in-modal--left--content-form'>
            <LoginForm />
          </div>
          <div className='sign-in-modal--left--content-helper-text'>
            <span className='link' onClick={createAccount}>
              <TranslationText i18nKey='create-account'>
                No account? Create account
              </TranslationText>
            </span>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Modal.Button
            className='sign-in-modal--left--content-demo-button'
            onClick={() =>
              modalActions.closeModal(undefined, MODAL_TRIGGER_TYPES.TRY_DEMO)
            }
          >
            <span className='sign-in-modal--demo-button-text'>
              <TranslationText i18nKey='try-demo'>Try the Demo</TranslationText>
              <Icon icon='arrow-right' />
            </span>
          </Modal.Button>
          <a className='sign-in-modal--pricing-text' href={menuLinks.PRICING}>
            <TranslationText i18nKey='learn-more-login'>
              Learn more about pricing &amp; features
            </TranslationText>
          </a>
        </Modal.Footer>
      </Modal.Panel>
      <Modal.Panel position='right'>
        <Modal.Button
          className='sign-in-modal--demo-button'
          onClick={() =>
            modalActions.closeModal(undefined, MODAL_TRIGGER_TYPES.TRY_DEMO)
          }
        >
          <span
            className='sign-in-modal--demo-button-text'
            data-cy='try-demo-button'
          >
            <TranslationText i18nKey='try-demo'>Try the Demo</TranslationText>
            <Icon icon='arrow-right' />
          </span>
        </Modal.Button>
      </Modal.Panel>
    </Modal>
  );
}
