import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  i18nKey?: string;
  children?: React.ReactNode;
  count?: number;
  customKeys?: {
    [key: string]: string;
  };
};

export default function TranslationText(props: Props) {
  const { i18nKey = '', children, count, customKeys } = props;

  const { t, ready, i18n } = useTranslation();

  const isEmpty = (str: string) => !str || !str.trim();
  const existsAndValid = ready
    ? (count ? i18n.exists(i18nKey, { count: count }) : i18n.exists(i18nKey)) &&
      !isEmpty(t(i18nKey))
    : false;

  if (ready && i18nKey && existsAndValid) {
    if (customKeys) {
      return t(i18nKey, customKeys);
    }
    if (count !== null) {
      t(i18nKey, { count: count });
    }
    return t(i18nKey);
  }
  if (children) {
    return children;
  }
  return '';
}
