import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UIState from '@/UILayer/State/UIState';

import { selectSVGViewbox, selectViewport } from '@/Redux/Slices/ViewportSlice';

// state management
import DefaultState from '../State/DefaultState';
import DesignState from '../State/DesignState';

// UIs
import DefaultUI from './Default/DefaultUI';
import PlanUI from './Plan/PlanUI';
import ReviewUI from './Review/ReviewUI';

// special modes
import ShapeShifterUI from './ShapeShifter/ShapeShifterUI';
import AnchorSelectionUI from './AnchorSelection/AnchorSelectionUI';
import ShapeShifterState from '../State/ShapeShifterState';
import ImportState from '../State/ImportState';
import ImportUI from './Import/ImportUI';

// possible UI layer modes
const MODES = {
  default: [DefaultUI, DesignState],
  'text-editor': [DefaultUI, DesignState], // TODO: conver to it's own UI layer
  plan: [PlanUI, DesignState],
  review: [ReviewUI, DefaultState],
  import: [ImportUI, ImportState],
  'shape-shifter': [ShapeShifterUI, ShapeShifterState],
  'anchor-selection': [AnchorSelectionUI, DefaultState],
} as const;

type Props = {
  ui: keyof typeof MODES;
};

export default function UILayer(props: Props) {
  const viewport = useSelector(selectViewport);
  const svgViewbox = useSelector(selectSVGViewbox);

  // used to trigger rendering
  const [, setLastUpdate] = useState(0);
  UIState.setListener(setLastUpdate);

  // get the correct mode
  const [UI, mode] = MODES[props.ui] || MODES.default;

  // create the UI state
  const ui = UIState.create(mode, {
    // default params
    viewport,
    svgViewbox,

    // provided props
    ...props,
  });

  return <UI {...props} ui={ui} />;
}
